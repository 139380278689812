"use client"

import { Mail, MapPin, Phone, Star } from "lucide-react"

import { GetSingleDealerReturnType } from "@/lib/server-api/dealers-types"

import { getDealerHours } from "../../lib/utils"
import { Badge } from "../ui/badge"
import { Button } from "../ui/button"
import {
  Card,
  CardContent,
  CardFooter,
  CardHeader,
  CardTitle,
} from "../ui/card"

import PhoneNumber from "@/components/phone-number/PhoneNumber"
interface PropsIf {
  dealer: GetSingleDealerReturnType
}

export default function DealerCardDetailed({ dealer }: PropsIf) {
  const getAddress = () => {
    const { address, city, state, zip } = dealer
    return `${address}, ${city}, ${state} ${zip}`
  }

  return (
    <Card>
      <CardHeader>
        {dealer.dealer_type !== "odp" && (
          <Badge className="mb-2" variant={"default"}>
            Featured
          </Badge>
        )}
        <CardTitle className="text-xl">{dealer.name}</CardTitle>
      </CardHeader>
      <CardContent className=" text-sm text-carbon-400">
        <div className="flex flex-col gap-6">
          <div className="flex flex-col gap-2">
            <label className="text-carbon-700 font-medium ">Hours</label>
            <ul className={"flex flex-col gap-2"}>
              {getDealerHours.days(dealer).map((day) => (
                <li key={day.day} className="text-sm flex justify-between">
                  {day.day}: <span>{day.hours}</span>
                </li>
              ))}
            </ul>
          </div>
          <div className="flex flex-col gap-2">
            <label className="text-carbon-700 font-medium">Contact</label>
            <div className="flex justify-between md:flex-row flex-col gap-2">
              <a
                className="w-fit"
                href={`https://www.google.com/maps/place/${encodeURIComponent(
                  getAddress()
                )}`}
                target="_blank"
                rel="noreferrer"
              >
                <ul className="text-sm" aria-label={"address"}>
                  <li>{dealer.address}</li>
                  <li>
                    {dealer.city}, {dealer.state} {dealer.zip}
                  </li>
                </ul>
              </a>
              <div className="flex flex-col md:items-end">
                <PhoneNumber currentDealer={dealer}/>
                <a
                  aria-label={"email"}
                  className="w-fit"
                  href={`mailto:${dealer.sales_email}?cc=confirmation@onedaydoorsandclosets.com`}
                >
                  {dealer.sales_email}
                </a>
              </div>
            </div>
          </div>
        </div>
      </CardContent>
      <CardFooter className="justify-between flex-wrap gap-2 flex-row">
        <div className="flex flex-row gap-2">
          <Button variant={"secondary"} size={"icon"} asChild>
            <a
              href={`mailto:${dealer.sales_email}?cc=confirmation@onedaydoorsandclosets.com`}
            >
              <Mail className="size-4" />
            </a>
          </Button>
          <Button variant={"secondary"} size={"icon"} asChild>
            <div>
              <PhoneNumber currentDealer={dealer} classNameObj={{container:'p-3'}} hideNumber>
                <Phone className="size-4" />
              </PhoneNumber>
            </div>
          </Button>
          <Button variant={"secondary"} size={"icon"} asChild>
            <a
              href={`https://www.google.com/maps/place/${encodeURIComponent(
                getAddress()
              )}`}
              target="_blank"
              rel="noreferrer"
            >
              <MapPin className="size-4" />
            </a>
          </Button>
        </div>

        {dealer._count.dealer_reviews > 0 && (
          <p className="flex flex-row flex-wrap items-center">
            4.9{" "}
            <Star className="ml-1 mr-2 size-4 text-amber-500 fill-amber-500" />{" "}
            ({dealer._count.dealer_reviews})
          </p>
        )}
      </CardFooter>
    </Card>
  )
}

"use client"

import { Content, asText } from "@prismicio/client"
import { PrismicNextImage } from "@prismicio/next"

import ConsultationForm from "@/components/forms/consultation/ConsultationForm"
import { SliceContextIf } from "@/lib/types/context"

interface PromotionHeroProps {
  slice: Content.HeroSlicePromotionHero;
  buttons: React.ReactNode;
  context: SliceContextIf
}

export default function PromotionHero({ slice, buttons, context}:PromotionHeroProps ): JSX.Element {


const whiteTextFormStyle= {
    name: "md:text-white",
    phone: "md:text-white",
    email: "md:text-white",
    address: "md:text-white",
    details: "md:text-white",
    source: "md:text-white",
    comment: "md:text-white",
    checkbox: "md:border-white",
    consent: "md:text-white",
  }
  return (
    <section className={"overflow-hidden relative bg-black/30 -mb-10 md:mb-0"}>

      <div className={'absolute -z-10 '}>
        <PrismicNextImage fallbackAlt={""}
                          priority={true}
                          loading={"eager"}
                          field={slice.items[0].image}
                          className="h-[440px] md:h-[800px] lg:h-[900px] object-cover"
                          />

      </div>

      <div className={'flex flex-col justify-between my-6 md:flex-row  lg:container'}>

        <div className="max-lg:container flex flex-col gap-4 pb-4 pt-12 lg:py-28 slide-in-from-bottom-6 animate-in fade-in-0 duration-700 lg:max-w-[450px] ">
          <h1 className={'font-copy text-white font-semibold text-6xl  text-shadow-lg shadow-gray-700 md:text-7xl lg:text-8xl '}>{asText(slice.primary.heading)}</h1>
          <p className={'text-white font-heading text-shadow-kg shadow-gray-600 text-2xl'}>{asText(slice.primary.copy)}</p>
          <div className={'max-w-[300px]'}>
            {buttons}
          </div>
        </div>

        <div className={'bg-white md:bg-carbon-500/50 p-4 md:p-6 md:m-6 md:min-w-[400px] max-w-[500px] md:h-[92%] h-full'}>
          <div className={'p-3 border md:border-carbon-400'}>
            <div className={'mb-4 text-center'}>
              <h2 className={'text-flame-default md:text-white'}>Exclusive Offer!</h2>
              <p className={'text-black md:text-white'}>Schedule a FREE Consultation & Lock in {"Today's"} Offer!</p>
            </div>
            <ConsultationForm  dealerOverride={context.dealerOverride} customStyle={whiteTextFormStyle}/>
          </div>
        </div>

      </div>

    </section>
  )
}
